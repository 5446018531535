var columns = [{
  title: '群等级',
  dataIndex: 'grade',
  key: 'grade',
  width: '20%',
  scopedSlots: {
    customRender: 'grade'
  }
}, {
  title: '升级群等级',
  dataIndex: 'gradeExpirence',
  key: 'gradeExpirence',
  width: '25%',
  scopedSlots: {
    customRender: 'gradeExpirence'
  }
}, {
  title: '等级对应奖励(cbk)',
  dataIndex: 'gradereward',
  key: 'gradereward',
  width: '25%',
  scopedSlots: {
    customRender: 'gradereward'
  }
}, {
  title: '发言活跃奖励(cbk)',
  dataIndex: 'speechreward',
  key: 'speechreward',
  width: '25%',
  scopedSlots: {
    customRender: 'speechreward'
  }
}, {
  title: '升级需要的(cbk)',
  dataIndex: 'amount',
  key: 'amount',
  width: '25%',
  scopedSlots: {
    customRender: 'amount'
  }
}, {
  title: '有效新用户奖励',
  dataIndex: 'activenewuserreward',
  key: 'activenewuserreward',
  width: '25%',
  scopedSlots: {
    customRender: 'activenewuserreward'
  }
},
// {
//     title: '色值',
//     dataIndex: 'colorvalue',
//     key: 'colorvalue',
//     width: '20%',
//     scopedSlots: {
//         customRender: 'colorvalue'
//     }
// },
{
  title: '群图标',
  dataIndex: 'iconurl',
  key: 'iconurl',
  width: 140,
  scopedSlots: {
    customRender: 'iconurl'
  }
}, {
  title: '背景图片',
  dataIndex: 'backendurl',
  key: 'backendurl',
  width: 140,
  scopedSlots: {
    customRender: 'backendurl'
  }
},
// {
//     title: '群状态',
//     dataIndex: 'isstatus',
//     key: 'isstatus',
//     width: '20%',
//     scopedSlots: {
//         customRender: 'isstatus'
//     }
// },
// {
//     title: '升级支付币种类型',
//     dataIndex: 'currencytype',
//     key: 'currencytype',
//     width: '25%',
//     scopedSlots: {
//         customRender: 'currencytype'
//     }
// },
{
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };