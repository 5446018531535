var columnsPerson = [{
  title: '群等级',
  dataIndex: 'grade',
  key: 'grade',
  width: '20%',
  scopedSlots: {
    customRender: 'grade'
  }
}, {
  title: '升级群等级',
  dataIndex: 'gradeid',
  key: 'gradeid',
  width: '20%',
  scopedSlots: {
    customRender: 'integral'
  }
}, {
  title: '群个人等级图标',
  dataIndex: 'iconurl',
  key: 'iconurl',
  // width: 140,
  scopedSlots: {
    customRender: 'iconurl'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columnsPerson };